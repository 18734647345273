function shortenString(text, length) {
  let shortenText = text.substr(0, length);
  shortenText = shortenText.substr(
    0,
    Math.min(length, shortenText.lastIndexOf(' ')),
  );
  return shortenText;
}

export default shortenString;
