import spinnerWhileLoading from 'core/libs/hoc/spinnerWhileLoading';
import Container from 'modules/common/Container';
import Head from 'modules/common/Head';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import PropTypes from 'prop-types';
import React, {lazy, Suspense} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {compose, lifecycle, mapProps, withProps} from 'recompose';
import {getNotifications} from '../actions';
import Pagination from '../components/Pagination';

const NotificationBox = lazy(() => import('../components/NotificationBox'));
const {PARTNER} = process.env;

const NotificationsContainer = (props) => {
  const intl = useIntl();
  const {formatMessage} = intl;
  const {page, notificationsData, totalPages, userData, results} = props;
  return (
    <Container className="bg-white pt5 pb4">
      <Head
        title={formatMessage({
          id:
            PARTNER === 'BARMEJ'
              ? 'helmet_notifications'
              : 'partner_helmet_notifications',
        })}
      />
      <div className="tr cf bg-light-gray ph4 pv4 br2">
        <h3 className="f4 fw6" data-cy="notifications-title">
          <FormattedMessage id="notifications" />
        </h3>
      </div>
      {notificationsData.status === 'fetched' &&
        (results.length > 0 ? (
          <>
            <Suspense fallback={<LoadingIndicator />}>
              <NotificationBox
                notificationsResults={results}
                userSlug={userData.slug}
              />
            </Suspense>
            <Pagination page={page} totalPages={totalPages} />
          </>
        ) : (
          <p className="pv4 black-80 f5 mr4">
            <FormattedMessage id="there_is_no_notifications_now" />
          </p>
        ))}
    </Container>
  );
};

NotificationsContainer.propTypes = {
  page: PropTypes.number.isRequired,
  notificationsData: PropTypes.object.isRequired,
  totalPages: PropTypes.number.isRequired,
  userData: PropTypes.object.isRequired,
  results: PropTypes.array.isRequired,
};

const mapStateToProps = ({notifications, user}, {page}) => ({
  notifications: notifications[page] || {
    data: {},
    status: 'not fetched',
  },
  user: user.profile,
});

const mapDispatchToProps = {
  dGetNotifications: getNotifications,
};

const enhance = compose(
  withProps(({page}) => {
    return {page: parseInt(page, 10) || 1};
  }),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      const {dGetNotifications, page} = this.props;
      dGetNotifications({force: true, page});
    },
    componentDidUpdate(prevProps) {
      const {dGetNotifications, page} = this.props;
      if (prevProps.page !== page) dGetNotifications({force: true, page});
    },
  }),
  spinnerWhileLoading(
    ({notifications, user}) =>
      notifications.status !== 'fetched' && user.status !== 'fetched',
  ),
  mapProps(({notifications, page, user, intl}) => ({
    results: notifications.data.results.filter(
      ({created_at}) => created_at > user.data.date_joined,
    ),
    page,
    totalPages: Math.ceil(notifications.data.count / 10),
    userData: user.data,
    notificationsData: notifications,
  })),
);

export default enhance(NotificationsContainer);
