import Link from 'modules/common/Link';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import caretLeft from 'theme/assets/images/caret-left.svg';
import caretRight from 'theme/assets/images/caret-right.svg';

const Pagination = ({page, totalPages = 1}) => {
  return (
    <div className="pb4 w-50-ns w-70-m w-90 center flex justify-between mt3">
      <div className="flex2">
        <Link // eslint-disable-line jsx-a11y/anchor-is-valid
          className={`no-underline dib ml2-ns mh1 pv2 ph2 ph3-ns ba bw1 br1 tc ${
            page > 1
              ? 'pointer white bg-green-light bg-animate hover-bg-green b--transparent'
              : 'light-gray b--light-gray'
          }`}
          rel="prev"
          to={page > 1 ? `/notifications/${page - 1}` : undefined}
          data-cy="notification-previous"
        >
          <img src={caretRight} className="ml2" alt="previous page" />
          <FormattedMessage id="prev_button" />
        </Link>
      </div>
      <div className="mt3">
        <p className="silver b" data-cy="notification-pages">
          <FormattedMessage
            id="pagination"
            values={{page: `${page}`, max: `${totalPages}`}}
          />
        </p>
      </div>
      <div className="flex2 tl">
        <Link
          className={`ml2-ns mh1 pv2 ph2 ph3-ns no-underline dib ba bw1 br1 tc ${
            page >= totalPages
              ? 'light-gray b--light-gray'
              : 'pointer white bg-green-light bg-animate hover-bg-green b--transparent'
          }`}
          rel="next"
          to={page < totalPages ? `/notifications/${page + 1}` : undefined}
          data-cy="notification-next"
        >
          <FormattedMessage id="next_button" />
          <img src={caretLeft} className="mr2" alt="next page" />
        </Link>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default Pagination;
