import {findSiteData} from 'core/libs/helpers/findSiteData';
import shortenString from 'core/libs/helpers/shortenString';
import withLocation from 'core/libs/hoc/withLocation';
import PropTypes from 'prop-types';
import React from 'react';
import {Helmet} from 'react-helmet';
import {compose, setPropTypes, withPropsOnChange} from 'recompose';

const Head = ({
  canonicalUrl,
  description,
  shortenedDescription,
  structuredData,
  title,
}) => (
  <Helmet>
    <script type="application/ld+json">{structuredData}</script>
    {title && <title>{title}</title>}
    <meta name="description" content={shortenedDescription || description} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:url" content={canonicalUrl} />
    <meta name="twitter:url" content={canonicalUrl} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
  </Helmet>
);

Head.propTypes = {
  canonicalUrl: PropTypes.string,
  structuredData: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  shortenedDescription: PropTypes.string,
};

const enhance = compose(
  withLocation,

  setPropTypes({
    description: PropTypes.string,
    name: PropTypes.string,
  }),

  withPropsOnChange(['location'], ({location: {pathname}}) => {
    return {
      canonicalUrl: findSiteData('mainUrl') + pathname.replace(/\/$/, ''),
    };
  }),

  withPropsOnChange(['description', 'name'], ({description, name}) => ({
    description: description || findSiteData('optionalDescription'),
    shortenedDescription:
      description && description.length > 150
        ? `${shortenString(description, 147)}...`
        : description,
    structuredData: `{
        "@context": "http://schema.org",
        "@type": "CreativeWork",
        "name": "${name}",
        "description": "${description}",
        "image": {
          "@type": "imageObject",
          "url": "${findSiteData('thumbnail')}",
          "height": "600",
          "width": "800"
        },
        "publisher": {
          "@type": "Organization",
          "name": "${findSiteData('siteName')}",
          "logo": {
          "@type": "imageObject",
            "url": "${findSiteData('thumbnail')}"
          }
        }
      }`,
  })),
);

export default enhance(Head);
