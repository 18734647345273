import React from 'react';
import {branch, renderComponent} from 'recompose';
import LoadingIndicator from 'modules/common/LoadingIndicator';

const modifiedLoadingIndicator = props => () => <LoadingIndicator {...props} />;

export default (
  isLoading, // isLoading: (props) => boolean
  props = {},
) => branch(isLoading, renderComponent(modifiedLoadingIndicator(props)));
