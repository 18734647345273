import React from 'react';
import PropTypes from 'prop-types';

const Container = ({children, className = ''}) => {
  return (
    <div className="w-80-ns w-90 center">
      <div className={`${className}`}>{children}</div>
    </div>
  );
};
Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Container;
