import {Redirect} from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {branch, compose, renderComponent, setPropTypes} from 'recompose';

const RedirectToAuth = () => <Redirect noThrow to="/auth/login" />;

export default compose(
  connect(({auth}) => ({authenticated: auth.authenticated})),
  setPropTypes({
    authenticated: PropTypes.bool.isRequired,
  }),
  branch(({authenticated}) => !authenticated, renderComponent(RedirectToAuth)),
);
