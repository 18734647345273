import React from 'react';
import {Match} from '@reach/router';
import redirectIfAnonymous from 'core/libs/hoc/redirectIfAnonymous';
import Notifications from 'modules/notifications/containers/NotificationsContainer';

const NotificationsPages = () => {
  return (
    <Match path="/notifications/:page">
      {({match}) => <Notifications page={match && match.page} />}
    </Match>
  );
};

export default redirectIfAnonymous(NotificationsPages);
